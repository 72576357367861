import React from "react";
import { FaTimes, FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import "../../assets/styles/ContactModal/ContactModal.css";

const ContactModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Cabecera del modal (Título + Botón Cerrar) */}
        <div className="modal-header">
          <h2>Información de Contacto</h2>
          <FaTimes className="close-button" onClick={onClose} />
        </div>

        {/* Cuerpo del modal con la info de contacto */}
        <div className="modal-body">
          <p className="contact-item">
            <FaEnvelope className="icon" />
            rentaautos@gmail.com
          </p>
          <p className="contact-item">
            <FaPhone className="icon" />
            +569 12345678
          </p>
          <p className="contact-item">
            <FaWhatsapp className="icon" />
            +569 12345678
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
