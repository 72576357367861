import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "../../assets/styles/FirstCarousel/FirstCarousel.css";

const BootstrapCarousel = () => {
  const [carouselImages, setCarouselImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, 3000); // Cambia cada 3 segundos

    return () => clearInterval(intervalId);
  }, [carouselImages]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  // Cargar imágenes desde la API al montar el componente
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${API_URL}/first-carousel-images`
        );
        if (response.ok) {
          const images = await response.json();
          setCarouselImages(images);
        } else {
          console.error("Error al obtener imágenes del carrusel.");
        }
      } catch (error) {
        console.error("Error en la solicitud de imágenes del carrusel:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="main-carousel-container">
      <div className="main-carousel" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {carouselImages.map((image, index) => (
          <div key={index} className="main-carousel-item">
            <img src={`${API_URL}${image.image_url}`} alt={`Carrusel ${index}`} />
          
          </div>
        ))}
      </div>
    </div>
  );

};

export default BootstrapCarousel;