import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/Admin/Login.css";

const Login = () => {
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                localStorage.setItem("user", JSON.stringify(result.user));
                if (result.user.role === "admin") {
                    navigate("/admin-dashboard");
                } else {
                    navigate("/");
                }
            } else {
                const result = await response.json();
                alert(result.message || "Error al iniciar sesión");
            }
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h1 className="login-title">Inicio de Sesión</h1>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Correo electrónico</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? "Ocultar" : "Mostrar"}
                            </button>
                        </div>
                    </div>
                    <button type="submit" className="login-button">Iniciar Sesión</button>
                </form>
                <p className="redirect-text">
                    ¿No tienes una cuenta?{" "}
                    <span onClick={() => navigate("/register")} className="redirect-link">
                        Regístrate
                    </span>
                </p>
                <button onClick={() => navigate("/")} className="back-button">
                    Volver al Home
                </button>
            </div>
        </div>
    );
};

export default Login;
