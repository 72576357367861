import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/AdminDashboard/AdminDashboard.css";
import Header from "../components/Header/Header";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const [carList, setCarList] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [carData, setCarData] = useState({
    image: "",
    brand: "",
    model: "",
    logo: "",
    description: "",
  });
  const [newCarouselImage, setNewCarouselImage] = useState("");
  const [editingCarouselImage, setEditingCarouselImage] = useState(null);
  const [carouselImageData, setCarouselImageData] = useState("");
  const [view, setView] = useState("cars");
  const [comentarios, setComentarios] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  // Verificación de usuario
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  // Fetch de autos y carrusel secundario
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await fetch(`${API_URL}/cars`);
        if (response.ok) {
          const cars = await response.json();
          setCarList(cars);
        } else {
          alert("Error al obtener los vehículos");
        }
      } catch (error) {
        console.error("Error al obtener los vehículos:", error);
      }
    };

    const fetchCarouselImages = async () => {
      try {
        const response = await fetch(`${API_URL}/carousel-images`);
        if (response.ok) {
          const images = await response.json();
          setCarouselImages(images);
        } else {
          setCarouselImages([]);
        }
      } catch (error) {
        console.error("Error al obtener las imágenes del carrusel:", error);
      }
    };

    fetchCars();
    fetchCarouselImages();
  }, []);

  // Manejo de formulario de autos
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarData({ ...carData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCar) {
      // Editar vehículo
      try {
        const response = await fetch(`${API_URL}/update-car/${selectedCar.id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(carData),
          }
        );
        if (response.ok) {
          alert("Vehículo actualizado exitosamente");
          setCarList((prev) =>
            prev.map((car) =>
              car.id === selectedCar.id ? { ...car, ...carData } : car
            )
          );
          setSelectedCar(null);
          setCarData({
            image: "",
            brand: "",
            model: "",
            logo: "",
            description: "",
          });
        } else {
          alert("Error al actualizar el vehículo");
        }
      } catch (error) {
        console.error("Error al actualizar el vehículo:", error);
      }
    } else {
      // Agregar vehículo
      try {
        const response = await fetch(`${API_URL}/add-car`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(carData),
        });
        if (response.ok) {
          const newCarResponse = await response.json();
          const newCar = {
            id: newCarResponse.carId,
            image: carData.image,
            brand: carData.brand,
            model: carData.model,
            logo: carData.logo,
            description: carData.description,
          };
          setCarList((prev) => [...prev, newCar]);
          setCarData({
            image: "",
            brand: "",
            model: "",
            logo: "",
            description: "",
          });
          alert("Vehículo agregado exitosamente");
        } else {
          alert("Error al agregar el vehículo");
        }
      } catch (error) {
        console.error("Error al agregar el vehículo:", error);
      }
    }
  };

  const handleEdit = (car) => {
    setSelectedCar(car);
    setCarData(car);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este vehículo?")) {
      try {
        const response = await fetch(`${API_URL}/delete-car/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setCarList((prev) => prev.filter((car) => car.id !== id));
          alert("Vehículo eliminado exitosamente");
        } else {
          alert("Error al eliminar el vehículo");
        }
      } catch (error) {
        console.error("Error al eliminar el vehículo:", error);
      }
    }
  };

  // Carrusel Secundario
  const handleCarouselChange = (e) => {
    setCarouselImageData(e.target.value);
  };

  const handleAddCarouselImage = async () => {
    if (!newCarouselImage) {
      alert("La URL de la imagen no puede estar vacía");
      return;
    }
    if (carouselImages.length >= 10) {
      alert("No puedes agregar más de 10 imágenes al carrusel");
      return;
    }
    try {
      const response = await fetch(`${API_URL}/add-carousel`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ image: newCarouselImage }),
      });
      if (response.ok) {
        const newImage = await response.json();
        setCarouselImages((prev) => [...prev, newImage]);
        setNewCarouselImage("");
        alert("Imagen agregada exitosamente al carrusel");
      } else {
        alert("Error al agregar la imagen al carrusel");
      }
    } catch (error) {
      console.error("Error al agregar la imagen al carrusel:", error);
    }
  };

  const handleCarouselEdit = (image) => {
    setEditingCarouselImage(image);
    setCarouselImageData(image.image);
  };

  const handleCarouselUpdate = async () => {
    if (!carouselImageData) {
      alert("La URL de la imagen no puede estar vacía");
      return;
    }
    try {
      const response = await fetch(`${API_URL}/${editingCarouselImage.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ image: carouselImageData }),
        }
      );
      if (response.ok) {
        alert("Imagen del carrusel actualizada exitosamente");
        setCarouselImages((prev) =>
          prev.map((img) =>
            img.id === editingCarouselImage.id
              ? { ...img, image: carouselImageData }
              : img
          )
        );
        setEditingCarouselImage(null);
        setCarouselImageData("");
      } else {
        alert("Error al actualizar la imagen del carrusel");
      }
    } catch (error) {
      console.error("Error al actualizar la imagen del carrusel:", error);
    }
  };

  const handleCarouselDelete = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta imagen?")) {
      try {
        const response = await fetch(`${API_URL}/delete-carousel/${id}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          alert(
            errorData.message || "Error al eliminar la imagen del carrusel"
          );
          return;
        }
        const result = await response.json();
        alert(result.message);
        setCarouselImages((prev) => prev.filter((img) => img.id !== id));
      } catch (error) {
        console.error("Error al eliminar la imagen del carrusel:", error);
        alert("Hubo un error al eliminar la imagen.");
      }
    }
  };

  // Logout
  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  // Nosotros
  const [nosotrosData, setNosotrosData] = useState({
    title: "",
    description: "",
    images: [
      { id: 1, url: "", label: "" },
      { id: 2, url: "", label: "" },
      { id: 3, url: "", label: "" },
      { id: 4, url: "", label: "" },
    ],
  });

  useEffect(() => {
    const fetchNosotrosContent = async () => {
      try {
        const response = await fetch(`${API_URL}/nosotros`);
        if (response.ok) {
          const data = await response.json();
          setNosotrosData({
            title: data.title,
            description: data.description,
            images: data.images,
          });
        } else {
          console.error("Error al cargar el contenido de 'Nosotros'");
        }
      } catch (error) {
        console.error("Error al obtener el contenido de 'Nosotros':", error);
      }
    };
    fetchNosotrosContent();
  }, []);

  const handleNosotrosChange = (e) => {
    const { name, value } = e.target;
    setNosotrosData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNosotrosImageChange = (id, field, value) => {
    setNosotrosData((prev) => ({
      ...prev,
      images: prev.images.map((img) =>
        img.id === id ? { ...img, [field]: value } : img
      ),
    }));
  };

  const handleSaveNosotros = async () => {
    const dataToSend = {
      title: nosotrosData.title,
      description: nosotrosData.description,
      images: nosotrosData.images,
    };
    try {
      const response = await fetch(`${API_URL}/update-nosotros`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        alert("Contenido de 'Nosotros' actualizado correctamente.");
      } else {
        alert("Error al actualizar el contenido de 'Nosotros'.");
      }
    } catch (error) {
      console.error("Error al guardar los cambios en 'Nosotros':", error);
    }
  };

  // Comentarios
  useEffect(() => {
    fetchComentarios();
  }, []);

  const fetchComentarios = async () => {
    try {
      const response = await fetch(`${API_URL}/comentarios`);
      if (response.ok) {
        const data = await response.json();
        setComentarios(data);
      } else {
        console.error("Error al cargar los comentarios");
      }
    } catch (error) {
      console.error("Error al obtener los comentarios:", error);
    }
  };

  const handleDeleteComnentarios = async (id) => {
    if (
      window.confirm("¿Estás seguro de que deseas eliminar este comentario?")
    ) {
      try {
        const response = await fetch(`${API_URL}/delete-comentario/${id}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          await fetchComentarios();
          alert("Comentario eliminado exitosamente.");
        } else {
          alert("Error al eliminar el comentario.");
        }
      } catch (error) {
        console.error("Error al eliminar el comentario:", error);
      }
    }
  };

  // Carrusel Principal (Slides)
  const [firstCarouselImages, setFirstCarouselImages] = useState([]);
  const [newFirstCarouselImage, setNewFirstCarouselImage] = useState("");
  const [editingFirstCarouselImage, setEditingFirstCarouselImage] =
    useState(null);
  const [firstCarouselImageData, setFirstCarouselImageData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchFirstCarouselImages = async () => {
      try {
        const response = await fetch(`${API_URL}/first-carousel-images`
        );
        if (response.ok) {
          const images = await response.json();
          setFirstCarouselImages(images);
        } else {
          console.error("Error al obtener imágenes del carrusel principal.");
        }
      } catch (error) {
        console.error("Error en la solicitud de imágenes del carrusel:", error);
      }
    };
    fetchFirstCarouselImages();
  }, []);

  const handleAddFirstCarouselImage = async () => {
    if (!selectedFile) {
      alert("Por favor, selecciona una imagen antes de agregarla.");
      return;
    }
    if (firstCarouselImages.length >= 4) {
      alert("No puedes agregar más de 4 imágenes al carrusel principal.");
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await fetch(`${API_URL}/add-first-carousel`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const newImage = await response.json();
        setFirstCarouselImages((prev) => [...prev, newImage]);
        setSelectedFile(null);
        document.getElementById("fileInput").value = "";
        alert("Imagen agregada exitosamente");
      } else {
        alert("Error al agregar la imagen");
      }
    } catch (error) {
      console.error("Error al agregar la imagen:", error);
    }
  };

  const handleUpdateFirstCarouselImage = async () => {
    if (!firstCarouselImageData) {
      alert("La URL de la imagen no puede estar vacía");
      return;
    }
    try {
      const response = await fetch(`${API_URL}/update-first-carousel/${editingFirstCarouselImage.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ image_url: firstCarouselImageData }),
        }
      );
      if (response.ok) {
        alert("Imagen actualizada correctamente");
        setFirstCarouselImages((prev) =>
          prev.map((img) =>
            img.id === editingFirstCarouselImage.id
              ? { ...img, image_url: firstCarouselImageData }
              : img
          )
        );
        setEditingFirstCarouselImage(null);
        setFirstCarouselImageData("");
      } else {
        alert("Error al actualizar la imagen");
      }
    } catch (error) {
      console.error("Error al actualizar la imagen:", error);
    }
  };

  const handleDeleteFirstCarouselImage = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta imagen?")) {
      try {
        const response = await fetch(`${API_URL}/delete-first-carousel/${id}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          alert("Imagen eliminada correctamente");
          setFirstCarouselImages((prev) => prev.filter((img) => img.id !== id));
        } else {
          alert("Error al eliminar la imagen");
        }
      } catch (error) {
        console.error("Error al eliminar la imagen:", error);
      }
    }
  };

  return (
    <div className="admin-dashboard">
      {/* Cabecera y header completo */}
      <Header />
      <div className="dashboard-header">
        <h1>Dashboard del Administrador</h1>
      </div>

      {/* Barra de navegación */}
      <div className="dashboard-navigation">
        <button
          onClick={() => setView("firstCarousel")}
          className={`nav-button ${view === "firstCarousel" ? "active" : ""}`}
        >
          Gestionar Carrusel Slides
        </button>

        <button
          onClick={() => setView("carousel")}
          className={`nav-button ${view === "carousel" ? "active" : ""}`}
        >
          Gestionar Carrusel Vehículos
        </button>

        <button
          onClick={() => setView("cars")}
          className={`nav-button ${view === "cars" ? "active" : ""}`}
        >
          Gestionar Catálogo
        </button>

        <button
          onClick={() => setView("nosotros")}
          className={`nav-button ${view === "nosotros" ? "active" : ""}`}
        >
          Gestionar Nosotros
        </button>

        <button
          onClick={() => setView("comentarios")}
          className={`nav-button ${view === "comentarios" ? "active" : ""}`}
        >
          Gestionar Comentarios
        </button>
      </div>

      {/* Secciones condicionales */}
      {view === "cars" ? (
        <>
          <div className="vehiculos-section">
            <h2>Gestionar Carrusel Vehículos</h2>

            <form className="vehiculos-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="image"
                placeholder="URL de la Imagen"
                value={carData.image}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="brand"
                placeholder="Marca del Vehículo"
                value={carData.brand}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="model"
                placeholder="Modelo del Vehículo"
                value={carData.model}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="logo"
                placeholder="URL del Logo"
                value={carData.logo}
                onChange={handleChange}
                required
              />
              <textarea
                name="description"
                placeholder="Descripción del Vehículo"
                value={carData.description}
                onChange={handleChange}
              />
              <button type="submit" className="vehiculos-submit-button">
                {selectedCar ? "Actualizar" : "Guardar"}
              </button>
            </form>

            <h3 className="vehiculos-list-title">Imágenes del Carrusel</h3>
            <div className="vehiculos-list">
              {carList.map((car) => (
                <div key={car.id} className="vehiculo-card">
                  <img
                    src={car.image}
                    alt={`${car.brand} ${car.model}`}
                    className="vehiculo-image"
                  />
                  <div className="vehiculo-info">
                    <img
                      src={car.logo}
                      alt={`${car.brand} logo`}
                      className="vehiculo-logo"
                    />
                    <h2>{`${car.brand} ${car.model}`}</h2>
                  </div>
                  {/* Contenedor flex para botones */}
                  <div className="vehiculo-buttons">
                    <button
                      onClick={() => handleEdit(car)}
                      className="vehiculo-edit-button"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDelete(car.id)}
                      className="vehiculo-delete-button"
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : view === "carousel" ? (
        <div className="carousel-section">
          <h2>Gestionar Carrusel Vehículos</h2>
          <div className="add-carousel">
            <h3>Agregar una nueva imagen al carrusel</h3>
            <input
              type="text"
              value={newCarouselImage}
              onChange={(e) => setNewCarouselImage(e.target.value)}
              placeholder="URL de la nueva imagen"
            />
            <button onClick={handleAddCarouselImage} className="add-button">
              Agregar Imagen
            </button>
          </div>

          <div className="carousel-images">
            {carouselImages.map((img) => (
              <div key={img.id} className="carousel-card">
                <img
                  src={img.image}
                  alt={`Carrusel ${img.id}`}
                  className="carousel-image1"
                />
                <button
                  onClick={() => handleCarouselEdit(img)}
                  className="edit-button"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleCarouselDelete(img.id)}
                  className="delete-button"
                >
                  Eliminar
                </button>
              </div>
            ))}
          </div>

          {editingCarouselImage && (
            <div className="edit-form">
              <input
                type="text"
                value={carouselImageData}
                onChange={handleCarouselChange}
                placeholder="URL de la imagen"
              />
              <button onClick={handleCarouselUpdate} className="update-button">
                Actualizar
              </button>
              <button
                onClick={() => setEditingCarouselImage(null)}
                className="cancel-button"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      ) : view === "nosotros" ? (
        <div className="nosotros-section1">
          <h2 className="titulo-nosotros-admin1">
            Editar Contenido de 'Nosotros'
          </h2>
          <input
            type="text"
            name="title"
            value={nosotrosData.title}
            onChange={handleNosotrosChange}
            placeholder="Título"
            className="nosotros-input1"
          />
          <textarea
            name="description"
            value={nosotrosData.description}
            onChange={handleNosotrosChange}
            placeholder="Descripción"
            className="nosotros-textarea1"
          />
          <div className="nosotros-images">
            {nosotrosData.images.map((image) => (
              <div key={image.id} className="nosotros-image-card1">
                <img
                  src={image.url}
                  alt={image.label}
                  className="nosotros-card-image1"
                />
                <input
                  type="text"
                  value={image.url}
                  onChange={(e) =>
                    handleNosotrosImageChange(image.id, "url", e.target.value)
                  }
                  placeholder="URL de la Imagen"
                  className="nosotros-input1"
                />
                <input
                  type="text"
                  value={image.label}
                  onChange={(e) =>
                    handleNosotrosImageChange(image.id, "label", e.target.value)
                  }
                  placeholder="Etiqueta"
                  className="nosotros-input1"
                />
              </div>
            ))}
          </div>
          <button onClick={handleSaveNosotros} className="save-button">
            Guardar Cambios
          </button>
        </div>
      ) : view === "comentarios" ? (
        <div className="dashboard-comentarios-container">
          <h2>Gestión de Comentarios</h2>
          <div className="comentarios-list">
            {comentarios.map((comentario) => (
              <div key={comentario.id} className="comentario-card">
                <img
                  src={
                    comentario.vehiculo_imagen ||
                    "https://via.placeholder.com/200"
                  }
                  alt={comentario.vehiculo}
                  className="comentario-imagen"
                />
                <div className="comentario-info">
                  <h3>{comentario.calificacion} ★</h3>
                  <p>
                    <strong>{comentario.nombre}</strong>
                  </p>
                  <p>{comentario.comentario}</p>
                </div>
                <button
                  onClick={() => handleDeleteComnentarios(comentario.id)}
                  className="delete-button"
                >
                  Eliminar
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : view === "firstCarousel" ? (
        <div className="slides-section">
          <h2>Gestionar Slides</h2>

          {/* Contenedor para subir nuevas imágenes */}
          <div className="slides-upload-container">
            {firstCarouselImages.length < 4 && (
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setSelectedFile(file);
                  }
                }}
              />
            )}
            {selectedFile && firstCarouselImages.length < 4 && (
              <button
                onClick={handleAddFirstCarouselImage}
                className="slides-add-button"
              >
                Agregar Imagen
              </button>
            )}
            {firstCarouselImages.length >= 4 && (
              <p className="slides-error-message">
                ⚠ No puedes agregar más de 4 imágenes al carrusel.
              </p>
            )}
          </div>

          {/* Listado de imágenes actuales en el carrusel */}
          <div className="slides-images">
            {firstCarouselImages.map((img) => (
              <div key={img.id} className="slide-card">
                <img
                  src={`${API_URL}${img.image_url}`}
                  alt={`Carrusel ${img.id}`}
                  className="slide-image1"
                />
                <button
                  onClick={() => handleDeleteFirstCarouselImage(img.id)}
                  className="delete-button"
                >
                  Eliminar
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AdminDashboard;
