import React from "react";
import "../../assets/styles/Sellos/Sellos.css"; // Importa el archivo CSS

// Reemplaza los "src" de las imágenes por tus propios íconos
import seguridadIcon from "../../assets/icons/seguridad.png";
import confianzaIcon from "../../assets/icons/confianza.png";
import calidadIcon from "../../assets/icons/calidad.png";

const Values = () => {
  return (
    <div className="values-container">
      {/* Tarjeta 1: Seguridad */}
      <div className="value-card">
        <div className="icon-wrapper">
          <img src={seguridadIcon} alt="Seguridad" className="value-icon" />
        </div>
        <h3 className="value-title">Seguridad</h3>
        <p className="value-description">Protección en cada viaje.</p>
      </div>

      {/* Tarjeta 2: Confianza */}
      <div className="value-card">
        <div className="icon-wrapper">
          <img src={confianzaIcon} alt="Confianza" className="value-icon" />
        </div>
        <h3 className="value-title">Confianza</h3>
        <p className="value-description">Transparencia que te respalda.</p>
      </div>

      {/* Tarjeta 3: Calidad */}
      <div className="value-card">
        <div className="icon-wrapper">
          <img src={calidadIcon} alt="Calidad" className="value-icon" />
        </div>
        <h3 className="value-title">Calidad</h3>
        <p className="value-description">Excelencia en cada detalle.</p>
      </div>
    </div>
  );
};

export default Values;
