import React, { useState, useEffect } from "react";
import "../../assets/styles/Comentarios/Comentarios.css";

const Comentarios = () => {
  const [comentarios, setComentarios] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [vehiculos, setVehiculos] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [comentariosPorPagina, setComentariosPorPagina] = useState(3); // Valor inicial

  const API_URL = process.env.REACT_APP_API_URL;

  const [nuevoComentario, setNuevoComentario] = useState({
    vehiculo: "",
    vehiculo_imagen: "",
    nombre: "",
    calificacion: "",
    comentario: "",
  });

  // Función para calcular el número de comentarios por página según el tamaño de la pantalla
  const calcularComentariosPorPagina = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      setComentariosPorPagina(4); // Pantallas grandes (xl)
    } else if (width >= 992) {
      setComentariosPorPagina(3); // Pantallas medianas (lg)
    } else if (width >= 768) {
      setComentariosPorPagina(2); // Pantallas pequeñas (md)
    } else if (width >= 576) {
      setComentariosPorPagina(1); // Pantallas muy pequeñas (sm)
    } else {
      setComentariosPorPagina(1); // Pantallas extra pequeñas (xs)
    }
  };

  // Efecto para calcular el número de comentarios por página al cargar y al cambiar el tamaño de la pantalla
  useEffect(() => {
    calcularComentariosPorPagina(); // Calcula inicialmente
    window.addEventListener("resize", calcularComentariosPorPagina); // Escucha cambios en el tamaño de la pantalla
    return () => {
      window.removeEventListener("resize", calcularComentariosPorPagina); // Limpia el listener
    };
  }, []);

  useEffect(() => {
    const fetchComentarios = async () => {
      try {
        const response = await fetch(`${API_URL}/comentarios`);
        if (response.ok) {
          const data = await response.json();
          setComentarios(data);
        } else {
          console.error("Error al cargar los comentarios");
        }
      } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    };

    const fetchVehiculos = async () => {
      try {
        const response = await fetch(`${API_URL}/cars`);
        if (response.ok) {
          const data = await response.json();
          setVehiculos(data);
        } else {
          console.error("Error al cargar los vehículos");
        }
      } catch (error) {
        console.error("Error al obtener los vehículos:", error);
      }
    };

    fetchComentarios();
    fetchVehiculos();
  }, []);

  // Cambia automáticamente los comentarios cada 5 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage(
        (prev) =>
          (prev + 1) % Math.ceil(comentarios.length / comentariosPorPagina)
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [comentarios, comentariosPorPagina]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "vehiculo") {
      const vehiculoSeleccionado = vehiculos.find((v) => v.model === value);

      setNuevoComentario({
        ...nuevoComentario,
        [name]: value,
        vehiculo_imagen: vehiculoSeleccionado?.image || "",
      });
    } else {
      setNuevoComentario({ ...nuevoComentario, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const vehiculoSeleccionado = vehiculos.find(
      (v) => v.model === nuevoComentario.vehiculo
    );

    if (!vehiculoSeleccionado) {
      alert("Por favor, selecciona un vehículo válido.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/add-comentario`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...nuevoComentario,
          vehiculo_imagen: vehiculoSeleccionado.image,
        }),
      });

      if (response.ok) {
        const nuevoComentarioGuardado = await response.json();
        setComentarios((prev) => [...prev, nuevoComentarioGuardado]);
        setModalVisible(false);
        setNuevoComentario({
          vehiculo: "",
          nombre: "",
          calificacion: "",
          comentario: "",
          vehiculo_imagen: "",
        });
        alert("Comentario enviado correctamente");
      } else {
        alert("Error al enviar el comentario");
      }
    } catch (error) {
      console.error("Error al guardar el comentario:", error);
    }
  };

  const handlePrevious = () => {
    setCurrentPage((prev) =>
      prev === 0
        ? Math.ceil(comentarios.length / comentariosPorPagina) - 1
        : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentPage(
      (prev) =>
        (prev + 1) % Math.ceil(comentarios.length / comentariosPorPagina)
    );
  };

  const comentariosAMostrar = comentarios.slice(
    currentPage * comentariosPorPagina,
    (currentPage + 1) * comentariosPorPagina
  );

  const renderStars = (calificacion) => {
    return "★".repeat(calificacion);
  };

  return (
    <div className="comentarios-container">
      <h2>Nuestros Clientes</h2>
      <p>Valoraciones</p>
      <div className="comentarios">
        <div className="comentarios-carousel-container">
          <button onClick={handlePrevious} className="carousel-button">
            <img src="/flechaIzquierda.png" alt="Flecha Izquierda" />
          </button>
          <div className="comentarios-carousel">
            {comentariosAMostrar.map((comentario, index) => (
              <div key={index} className="comentario-card">
                <img
                  src={
                    comentario.vehiculo_imagen ||
                    "https://via.placeholder.com/200"
                  }
                  alt={comentario.vehiculo}
                  className="comentario-imagen"
                />
                <div className="comentario-info">
                  <h3>
                    {comentario.calificacion}.0{" "}
                    {renderStars(comentario.calificacion)}
                  </h3>
                  <p className="p-nombre">
                    <strong>{comentario.nombre}</strong>
                  </p>
                  <p className="p-texto">{comentario.comentario}</p>
                </div>
              </div>
            ))}
          </div>
          <button onClick={handleNext} className="carousel-button">
            <img src="/flechaDerecha.png" alt="Flecha Derecha" />
          </button>
        </div>
        <button
          onClick={() => setModalVisible(true)}
          className="comentarios-btn"
        >
          Comenta tu experiencia
        </button>
      </div>

      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h3>Agregar Comentario</h3>
            <form onSubmit={handleSubmit}>
              <select
                name="vehiculo"
                value={nuevoComentario.vehiculo}
                onChange={handleInputChange}
                required
              >
                <option value="">Selecciona un vehículo</option>
                {vehiculos.map((vehiculo) => (
                  <option key={vehiculo.id} value={vehiculo.model}>
                    {vehiculo.brand} {vehiculo.model}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="nombre"
                placeholder="Nombre y Apellido"
                value={nuevoComentario.nombre}
                onChange={handleInputChange}
                required
              />
              <input
                type="number"
                name="calificacion"
                placeholder="Calificación (1-5)"
                value={nuevoComentario.calificacion}
                onChange={handleInputChange}
                min="1"
                max="5"
                required
              />
              <textarea
                name="comentario"
                placeholder="Comentario"
                value={nuevoComentario.comentario}
                onChange={handleInputChange}
                required
              ></textarea>
              <button type="submit" className="modal-submit-btn">
                Enviar
              </button>
              <button
                onClick={() => setModalVisible(false)}
                className="modal-cancel-btn"
              >
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Comentarios;