import React from "react";
import { Nav } from "react-bootstrap";
import "../../assets/styles/NavBar/NavBar.css";

const McRentaNav = ({ isOpen, onClose, onContactClick }) => {
  // Evita la navegación y abre el modal
  const handleContactClick = (e) => {
    e.preventDefault();
    onClose();       // Cierra el menú móvil, si corresponde
    onContactClick();
  };

  return (
    <nav className={`navbar ${isOpen ? "open" : ""}`}>
      <div className="navbar-content">
        <Nav.Link href="/#catalogo" onClick={onClose}>
          Catálogo
        </Nav.Link>
        <Nav.Link href="/Nuestra-Empresa" onClick={onClose}>
          Nosotros
        </Nav.Link>
        {/* Al hacer clic en Contacto, abrimos el modal (en lugar de cambiar de página) */}
        <Nav.Link href="#contacto" onClick={handleContactClick}>
          Contacto
        </Nav.Link>
      </div>
    </nav>
  );
};

export default McRentaNav;
