import React, { useState, useEffect } from "react";
import "../assets/styles/Nosotros/Nosotros.css";

const Nosotros = () => {
    const [content, setContent] = useState({
        title: "",
        description: "",
        images: [],
    });

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await fetch(`${API_URL}/nosotros`);
                if (response.ok) {
                    const data = await response.json();
                    setContent(data);
                } else {
                    console.error("Error al cargar el contenido de 'Nosotros'");
                }
            } catch (error) {
                console.error("Error al obtener el contenido de 'Nosotros':", error);
            }
        };

        fetchContent();
    }, []);

    return (
        <div className="nosotros-father">
            <div className="nosotros-section">
            <div className="nosotros-grid">
                <div className="nosotros-column-1">
                <h3>{content.title}</h3>
                <p>{content.description}</p>
                    {content.images[0] && (
                        <div className="nosotros-image-card-1">
                            <img src={content.images[0].url} alt={content.images[0].label} className="nosotros-card-image" />
                            <p className="nosotros-p-card-1">{content.images[0].label}</p>
                        </div>
                    )}
                </div>
                <div className="nosotros-column-2">
                    {content.images[1] && (
                        <div className="nosotros-image-card-2">
                            <img src={content.images[1].url} alt={content.images[1].label} className="nosotros-card-image" />
                            <p className="nosotros-p-card-2">{content.images[1].label}</p>
                        </div>
                    )}
                </div>
                <div className="nosotros-column-3">
                    {content.images[2] && (
                        <div className="nosotros-image-card-3">
                            <img src={content.images[2].url} alt={content.images[2].label} className="nosotros-card-image" />
                            <p className="nosotros-p-card-3">{content.images[2].label}</p>
                        </div>
                    )}
                    {content.images[3] && (
                        <div className="nosotros-image-card-3">
                            <img src={content.images[3].url} alt={content.images[3].label} className="nosotros-card-image" />
                            <p className="nosotros-p-card-3">{content.images[3].label}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </div>
        
    );
};

export default Nosotros;
