import React, { useEffect, useState } from "react";
import "../../assets/styles/CotizaConNosotros/CotizaConNosotros.css"; // Asegúrate de que la ruta sea correcta
import { FaCalendarAlt } from "react-icons/fa"; // Importa un ícono de calendario

const CotizaConNosotros = () => {
  const [vehiculos, setVehiculos] = useState([]);
  const [id_vehiculo, setId_vehiculo] = useState("");
  const [loading, setLoading] = useState(true);
  const [fechaReserva, setFechaReserva] = useState(""); // Estado para controlar la fecha
  const [rut, setRut] = useState(""); // Estado para el RUT
  const [nombreApellido, setNombreApellido] = useState("");
  const [numeroContacto, setNumeroContacto] = useState("");
  const [errors, setErrors] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const FetchVehiculosCotiza = async () => {
      try {
        const response = await fetch(`${API_URL}/cars`);
        if (response.ok) {
          const vehiculosData = await response.json();
          console.log("Vehículos recibidos:", vehiculosData);
          setVehiculos(vehiculosData);
        } else {
          console.error("Error al obtener vehículos del catálogo");
        }
      } catch (error) {
        console.error("Error en la solicitud de vehículos:", error);
      } finally {
        setLoading(false);
      }
    };

    FetchVehiculosCotiza();
  }, []);

  // Bloqueo de teclas para Nombre y Apellido
  const handleNombreApellidoKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey || e.key === "Backspace" || e.key === "Tab")
      return;
    if (!/^[a-zA-Z\s]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Bloqueo de teclas para Número de Contacto (solo dígitos)
  const handleNumeroContactoKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey || e.key === "Backspace" || e.key === "Tab")
      return;
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Bloqueo de teclas para el RUT (dígitos, puntos, guión y k/K)
  const handleRutKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey || e.key === "Backspace" || e.key === "Tab")
      return;
    if (!/[0-9kK]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Función que formatea el RUT automáticamente, limitándolo a 9 caracteres (por ejemplo, 12.345.678-9)
  const formatRut = (value) => {
    // Elimina cualquier carácter que no sea dígito o k/K y limita la longitud a 9 caracteres
    let rutNum = value.replace(/[^0-9kK]/g, "").slice(0, 9);
    if (rutNum === "") return "";
    // Si hay más de 1 carácter, se asume que el último es el dígito verificador
    if (rutNum.length > 1) {
      const body = rutNum.slice(0, -1);
      const dv = rutNum.slice(-1);
      return formatRutBody(body) + "-" + dv;
    } else {
      return rutNum;
    }
  };

  // Función auxiliar que inserta puntos al cuerpo del RUT
  const formatRutBody = (body) => {
    // Invierte el string para agrupar de tres en tres
    let reversed = body.split("").reverse().join("");
    let groups = reversed.match(/.{1,3}/g);
    let withDots = groups.join(".");
    return withDots.split("").reverse().join("");
  };

  // Maneja el cambio del RUT formateándolo automáticamente
  const handleRutChange = (e) => {
    setRut(formatRut(e.target.value));
  };

  // Maneja el cambio de fecha mientras se escribe (formateo dd-mm-yyyy)
  const handleFechaReservaChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (value.length > 0) {
      formattedValue += value.substr(0, 2);
    }
    if (value.length > 2) {
      formattedValue += "-" + value.substr(2, 2);
    }
    if (value.length > 4) {
      formattedValue += "-" + value.substr(4, 4);
    }
    setFechaReserva(formattedValue || "");
  };

  // Cuando se selecciona la fecha mediante el input type="date"
  const handleDateChange = (e) => {
    const selectedDate = e.target.value.split("-");
    const formattedDate =
      selectedDate[2] + "-" + selectedDate[1] + "-" + selectedDate[0];
    setFechaReserva(formattedDate);
  };

  const validateForm = () => {
    const validationErrors = {};

    // Validación Nombre y Apellido
    if (!nombreApellido.trim()) {
      validationErrors.nombreApellido = "El nombre y apellido es obligatorio.";
    } else if (!/^[a-zA-Z\s]+$/.test(nombreApellido)) {
      validationErrors.nombreApellido = "Solo se permiten letras y espacios.";
    }

    // Validación RUT (ejemplo: 12.345.678-9)
    if (!rut.trim()) {
      validationErrors.rut = "El RUT es obligatorio.";
    } else if (!/^[0-9]{1,2}\.?[0-9]{3}\.?[0-9]{3}-[0-9kK]{1}$/.test(rut)) {
      validationErrors.rut = "Formato de RUT inválido (Ej: 12.345.678-9).";
    }

    // Validación Número de Contacto
    if (!numeroContacto.trim()) {
      validationErrors.numeroContacto = "El número de contacto es obligatorio.";
    } else if (!/^\d+$/.test(numeroContacto)) {
      validationErrors.numeroContacto = "Solo se permiten dígitos.";
    }

    // Validación Selector de Vehículo
    if (!id_vehiculo) {
      validationErrors.id_vehiculo = "Debe seleccionar un vehículo.";
    }

    // Validación Fecha de Reserva (formato dd-mm-yyyy)
    if (!fechaReserva.trim()) {
      validationErrors.fechaReserva = "La fecha de reserva es obligatoria.";
    } else if (!/^\d{2}-\d{2}-\d{4}$/.test(fechaReserva)) {
      validationErrors.fechaReserva = "El formato debe ser dd-mm-yyyy.";
    } else {
      const [day, month, year] = fechaReserva.split("-");
      const dateObj = new Date(`${year}-${month}-${day}`);
      if (isNaN(dateObj.getTime())) {
        validationErrors.fechaReserva = "La fecha ingresada no es válida.";
      }
    }

    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // Aquí iría la lógica para enviar los datos al servidor
      alert("Formulario válido. Procesando solicitud...");
    }
  };

  return (
    <div className="cotiza-container">
      <h2>Cotiza con Nosotros</h2>
      <form className="cotiza-form" onSubmit={handleSubmit}>
        {/* Nombre y Apellido */}
        <div className="form-group">
          <input
            type="text"
            id="nombreApellido"
            name="nombreApellido"
            placeholder="Nombre y Apellido"
            value={nombreApellido}
            onChange={(e) => setNombreApellido(e.target.value)}
            onKeyDown={handleNombreApellidoKeyDown}
            required
          />
          {errors.nombreApellido && (
            <p className="error-text">{errors.nombreApellido}</p>
          )}
        </div>

        {/* RUT */}
        <div className="form-group">
          <input
            type="text"
            id="rut"
            name="rut"
            placeholder="RUT (Ej: 12.345.678-9)"
            value={rut}
            onChange={handleRutChange}
            onKeyDown={handleRutKeyDown}
            required
          />
          {errors.rut && <p className="error-text">{errors.rut}</p>}
        </div>

        {/* Número de Contacto */}
        <div className="form-group">
          <input
            type="tel"
            id="numeroContacto"
            name="numeroContacto"
            placeholder="Número de Contacto"
            value={numeroContacto}
            onChange={(e) => setNumeroContacto(e.target.value)}
            onKeyDown={handleNumeroContactoKeyDown}
            required
          />
          {errors.numeroContacto && (
            <p className="error-text">{errors.numeroContacto}</p>
          )}
        </div>

        {/* Selector de Vehículo */}
        <div className="form-group selector-container">
          <label htmlFor="vehiculo" style={{ display: "none" }}>
            Seleccione un vehículo
          </label>
          <select
            id="vehiculo"
            name="vehiculo"
            className="selector"
            value={id_vehiculo}
            onChange={(e) => setId_vehiculo(e.target.value)}
            required
          >
            <option value="" disabled>
              Seleccione un vehículo
            </option>
            {vehiculos.map((vehiculo) => (
              <option key={vehiculo.id} value={vehiculo.id}>
                {vehiculo.brand} {vehiculo.model}
              </option>
            ))}
          </select>
          {errors.id_vehiculo && (
            <p className="error-text">{errors.id_vehiculo}</p>
          )}
        </div>

        {/* Fecha de Reserva */}
        <div className="form-group date-input-container">
          <input
            type="text"
            id="fechaReserva"
            name="fechaReserva"
            placeholder="dd-mm-yyyy"
            value={fechaReserva}
            onChange={handleFechaReservaChange}
            onBlur={() => !fechaReserva && setFechaReserva("")}
            pattern="\d{2}-\d{2}-\d{4}"
            required
          />
          <FaCalendarAlt
            className="calendar-icon"
            onClick={() =>
              document.getElementById("fechaReservaDate").showPicker()
            }
          />
          <input
            type="date"
            id="fechaReservaDate"
            name="fechaReservaDate"
            className="hidden-date-input"
            onChange={handleDateChange}
          />
          {errors.fechaReserva && (
            <p className="error-text">{errors.fechaReserva}</p>
          )}
        </div>

        {/* Botón Enviar */}
        <button type="submit" className="cotiza-button">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default CotizaConNosotros;
