import React, { useState } from "react";
import "../../assets/styles/NuestraEmpresa/NuestraEmpresa.css"; // Asegúrate de que la ruta sea correcta
import Header from "../Header/Header";

// Reemplaza estas imágenes con las tuyas (ruta local o URL)
import image1 from "../../assets/images/nosotros.jpg";
import image2 from "../../assets/images/nosotros.jpg";
import image3 from "../../assets/images/nosotros.jpg";

// Importamos el modal de contacto
import ContactModal from "../../components/ContactModal/ContactModal";

const NuestraEmpresa = () => {
  // Estado para controlar la visibilidad del modal de contacto
  const [showContactModal, setShowContactModal] = useState(false);

  // Funciones para abrir/cerrar el modal de contacto
  const openContactModal = () => setShowContactModal(true);
  const closeContactModal = () => setShowContactModal(false);

  return (
    <div className="nuestra-empresa">
      {/* Header: le pasamos la función para abrir el modal */}
      <Header onContactClick={openContactModal} />

      {/* Sección: Nuestra Empresa */}
      <section
        className="nosotros-section section-nuestra-empresa"
        style={{ width: "1000px" }}
      >
        <div className="nosotros-content">
          <h2
            className="nosotros-title"
            style={{
              textAlign: "start",
              marginLeft: "-200px",
              marginTop: "-40px",
            }}
          >
            Nuestra Empresa
          </h2>
          <p
            className="nosotros-description"
            style={{ textAlign: "start", marginLeft: "-200px" }}
          >
            MC Rent A Car es tu aliado en alquiler de vehículos. Ofrecemos
            soluciones de movilidad, adaptándonos a tus necesidades, ya sea para
            transporte de carga, logística empresarial o en tus aventuras. En MC
            Rent A Car, nos enorgullece ser parte de tu camino.
          </p>
          <button className="nosotros-button" style={{ marginLeft: "-860px" }}>
            Conoce más
          </button>
        </div>
        <div className="nosotros-image-container">
          <img src={image1} alt="Nuestra Empresa" className="nosotros-image" />
        </div>
      </section>

      {/* Sección: Misión */}
      <section
        className="nosotros-section reverse section-mision"
        style={{ width: "1000px" }}
      >
        <div className="nosotros-content">
          <h2
            className="nosotros-title"
            style={{
              textAlign: "end",
              marginRight: "-200px",
              marginTop: "-40px",
            }}
          >
            Misión
          </h2>
          <p
            className="nosotros-description"
            style={{ textAlign: "end", marginRight: "-200px" }}
          >
            En MC Rent A Car, nuestra misión es proporcionar soluciones de
            movilidad accesibles y personalizadas, adaptándonos a las
            necesidades de empresas y particulares. Nos comprometemos a ofrecer
            un servicio confiable, con vehículos en óptimas condiciones y un
            trato cercano que garantice la satisfacción de nuestros clientes en
            cada viaje.
          </p>
        </div>
        <div className="nosotros-image-container">
          <img src={image2} alt="Misión" className="nosotros-image" />
        </div>
      </section>

      {/* Sección: Propósito */}
      <section
        className="nosotros-section section-proposito"
        style={{ width: "1000px" }}
      >
        <div className="nosotros-content">
          <h2
            className="nosotros-title"
            style={{
              textAlign: "start",
              marginLeft: "-200px",
              marginTop: "-40px",
            }}
          >
            Propósito
          </h2>
          <p
            className="nosotros-description"
            style={{ textAlign: "start", marginLeft: "-200px" }}
          >
            Nuestro propósito es ser un apoyo clave en la logística y movilidad
            de nuestros clientes, contribuyendo al crecimiento de las empresas y
            particulares. Queremos ser reconocidos como una opción confiable y
            cercana en el alquiler de vehículos, ayudando a nuestros clientes a
            llegar a su destino de manera segura y eficiente.
          </p>
        </div>
        <div className="nosotros-image-container">
          <img src={image3} alt="Propósito" className="nosotros-image" />
        </div>
      </section>

      {/* Renderizamos el modal si showContactModal es true */}
      {showContactModal && <ContactModal onClose={closeContactModal} />}
    </div>
  );
};

export default NuestraEmpresa;
