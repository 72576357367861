import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/Home/Home.css";

// Componentes principales
import Nosotros from "./Nosotros";
import Comentarios from "../features/Client/Comentarios";
import FirstCarousel from "../components/FirstCarousel/FirstCarousel";
import CotizaConNosotros from "../components/CotizaConNosotros/CotizaConNosotros";
import Sellos from "../components/Sellos/Sellos";
import Header from "../components/Header/Header";

// Swiper para carrusel de modelos
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// Modal de contacto
import ContactModal from "../components/ContactModal/ContactModal";

const Home = () => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const [carList, setCarList] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showContactModal, setShowContactModal] = useState(false);
  const [flipped, setFlipped] = useState({});

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const handleCotizar = () => {
    const cotizaSection = document.getElementById("cotiza");
    if (cotizaSection) {
      const headerOffset = 580;
      const elementPosition = cotizaSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (window.location.hash === "#catalogo") {
      const catalogoSection = document.getElementById("catalogo");
      if (catalogoSection) {
        catalogoSection.classList.add("animate-catalogo");
        catalogoSection.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          catalogoSection.classList.remove("animate-catalogo");
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    const handleCatalogoClick = (e) => {
      if (
        window.location.pathname === "/" &&
        e.target.closest('a[href="/#catalogo"]')
      ) {
        e.preventDefault();
        const catalogoSection = document.getElementById("catalogo");
        if (catalogoSection) {
          catalogoSection.classList.add("animate-catalogo");
          catalogoSection.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            catalogoSection.classList.remove("animate-catalogo");
          }, 1000);
        }
      }
    };
    document.addEventListener("click", handleCatalogoClick);
    return () => document.removeEventListener("click", handleCatalogoClick);
  }, []);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await fetch(`${API_URL}/cars`);
        if (response.ok) {
          const cars = await response.json();
          setCarList(cars);
        } else {
          alert("Error al obtener los vehículos");
        }
      } catch (error) {
        console.error("Error al obtener los vehículos:", error);
      }
    };

    const fetchCarouselImages = async () => {
      try {
        const response = await fetch(`${API_URL}/carousel-images`);	
        if (response.ok) {
          const images = await response.json();
          setCarouselImages(images);
        } else {
          setCarouselImages([]);
        }
      } catch (error) {
        console.error("Error al obtener las imágenes del carrusel:", error);
      }
    };

    fetchCars();
    fetchCarouselImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        carouselImages.length ? (prevIndex + 2) % carouselImages.length : 0
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [carouselImages]);

  const toggleFlip = (id) => {
    setFlipped((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/login");
  };

  const openContactModal = () => setShowContactModal(true);
  const closeContactModal = () => setShowContactModal(false);

  return (
    <div className="main-container">
      <div className="home-container">
        <Header onContactClick={openContactModal} />
        <FirstCarousel />

        <div className="vehiculo-carousel-container">
          {carouselImages.length > 0 && (
            <div className="vehiculo-carousel">
              <img
                src={carouselImages[currentImageIndex]?.image}
                alt={`Carrusel ${currentImageIndex + 1}`}
                className="vehiculo-carousel-image"
              />
            </div>
          )}
          <div id="cotiza">
            <CotizaConNosotros />
          </div>
        </div>

        <div className="modelos-disponibles" id="catalogo">
          <h2>Modelos Disponibles</h2>
          {/* Usamos un contenedor extra para el Swiper */}
          <div className="swiper-outer-wrapper">
            <Swiper
              modules={[Navigation]}
              spaceBetween={10}
              slidesPerView={4}
              navigation={true}
            >
              {carList.map((car) => (
                <SwiperSlide key={car.id}>
                  <div className="car-card">
                    <div className={`card-inner ${flipped[car.id] ? "flipped" : ""}`}>
                      <div className="card-front">
                        <img
                          src={car.image}
                          alt={`${car.brand} ${car.model}`}
                          className="car-image"
                        />
                        <div className="car-info">
                          <div className="logo-container">
                            <img
                              src={car.logo}
                              alt={`${car.brand} logo`}
                              className="car-logo"
                            />
                          </div>
                          <h2 className="car-model">{`${car.brand} ${car.model}`}</h2>
                        </div>
                        <button
                          className="detalle-button"
                          onClick={() => toggleFlip(car.id)}
                        >
                          Detalle
                        </button>
                      </div>
                      <div className="card-back">
                        <h3>{`${car.brand} ${car.model}`}</h3>
                        <p>{car.description}</p>
                        <button
                          className="detalle-button"
                          onClick={() => toggleFlip(car.id)}
                        >
                          Volver
                        </button>
                        <button
                          className="detalle-button"
                          onClick={handleCotizar}
                        >
                          Cotizar
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <Nosotros />
        <Comentarios />
        <Sellos />

        {showContactModal && <ContactModal onClose={closeContactModal} />}
      </div>
    </div>
  );
};

export default Home;
