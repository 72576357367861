import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaInstagram, FaFacebook } from "react-icons/fa";
import "../../assets/styles/Header/Header.css";
import McRentaNav from "../Navbar/Navbar";

const Header = ({ onContactClick }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/login");
  };

  const toggleMenu = () => {
    setIsNavOpen((prev) => !prev);
  };

  return (
    <header className="header">
      {/* Izquierda: Logo */}
      <div className="header-left">
        <a href="/">
          <img
            src="/logo-oficial.png"
            alt="Logo Rent Cars"
            className="header-logo"
          />
        </a>
      </div>

      <McRentaNav
        isOpen={isNavOpen}
        onClose={() => setIsNavOpen(false)}
        onContactClick={onContactClick} // Pasamos la función
      />

      {/* Derecha: Usuario + Redes + Menú móvil */}
      <div className="header-right">
        {user && (
          <div className="user-logout-container">
            <h2 className="user-session">Sesión: {user.username}</h2>
            <button onClick={handleLogout} className="logout-button">
              Cerrar Sesión
            </button>
          </div>
        )}

        <div className="container-redes-sociales">
          <div
            className="container-instagram"
            onClick={() =>
              window.open(
                "https://www.instagram.com/mcrentacar.cl?igsh=dmY1ZmhtdjVxNnU3",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <FaInstagram size={30} />
          </div>
          <div
            className="container-instagram"
            onClick={() =>
              window.open(
                "https://www.facebook.com/share/1K3w7aCk5W/?mibextid=wwXIfr",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <FaFacebook size={30} />
          </div>
        </div>

        {/* Botón Menú Hamburguesa (solo móvil) */}
        <div className="mobile-menu-toggle" onClick={toggleMenu}>
          <FaBars size={25} />
        </div>
      </div>
    </header>
  );
};

export default Header;
