import React from 'react';
import '../assets/styles/Footer/Footer.css';
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const handleRedirect = (url) => {
        window.open(url, '_blank');
    };
    const handleEmpresaClick = () => {
        if (user) {
            navigate("/admin-dashboard");
        } else {
            navigate("/login");
        }
    };

    return (
        <footer className="footer">
            <div className="footer-superior">
                {/* Sección 1: Logo y redes sociales */}
                <div className="footer-section footer-section-1">
                    <div className="footer-logo">
                        <img src="/logo-oficial2.png" alt="Logo Rent Cars" />
                    </div>
                    <div className="footer-rrss">
                        <div className="rrss-icon">
                            <img src="/iconoInstagram.webp" alt="Instagram" />
                        </div>
                        <div className="rrss-icon">
                            <img src="/iconoWsp.webp" alt="Wsp" />
                        </div>
                        <div className="rrss-icon">
                            <img src="/iconoFacebook.webp" alt="Facebook" />
                        </div>
                    </div>
                </div>

                {/* Sección 2: Renta Autos */}
                <div className="footer-section footer-section-2">
                    <h3 className="footer-titulo">Renta Autos</h3>
                    <h4 onClick={() => handleRedirect('s')}>Sucursales</h4>
                    <h4 onClick={() => handleRedirect('')}>Convenios y Empresa</h4>
                    <h4 onClick={() => handleRedirect('')}>Preguntas Frecuentes</h4>
                    <h4 onClick={() => handleRedirect('')}>Términos y Condiciones</h4>
                </div>

                {/* Sección 3: Hablemos */}
                <div className="footer-section footer-section-3">
                    <h3 className="footer-titulo">Hablemos</h3>
                    <h4 onClick={() => handleRedirect('')}>
                        <img src="/telefono.webp" alt="Teléfono" className="footer-icon" />
                        +569 1234 5678
                    </h4>
                    <h4 onClick={() => handleRedirect('')}>
                        <img src="/carta.webp" alt="Correo" className="footer-icon" />
                        Rentaautos@gmail.com
                    </h4>
                    <h4 onClick={() => handleRedirect('')}>Medios de Pago</h4>
                    <img src="/iconoWebpay.webp" alt="Webpay" className="medios-pago" />
                </div>

                {/* Sección 4: Corporativo */}
                <div className="footer-section footer-section-4">
                    <h3 className="footer-titulo">Corporativo</h3>
                    <h4 onClick={() => handleRedirect('')}>Trabaja con Nosotros</h4>
                    <button onClick={handleEmpresaClick}>Empresa</button>
                </div>
            </div>

            {/* Footer inferior */}
            <div className="footer-inferior">
                <p>Todos los derechos reservados. 2025.</p>
            </div>
        </footer>
    );
};

export default Footer;