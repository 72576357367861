import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./features/Admin/Login";
import Register from "./features/Admin/Register";
import AdminDashboard from "./layouts/AdminDashboard";
import Nosotros from "./pages/Nosotros";
import Footer from "./pages/Footer"; // Importar el componente Footer
import NuestraEmpresa from "../src/components/Nuestra Empresa/NuestraEmpresa"; // Importar el componente NuestraEmpresa

const App = () => {
  const [carList, setCarList] = useState([]);

  const addCar = (car) => {
    setCarList([...carList, car]); // Agregar el auto a la lista
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home carList={carList} />} />
        <Route
          path="/admin-dashboard"
          element={<AdminDashboard addCar={addCar} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/nosotros" element={<Nosotros />} />

        {/* Ruta para Nuestra Empresa */}
        <Route path="/nuestra-empresa" element={<NuestraEmpresa />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
